<template>
  <iq-card body-class="p-0" class="iqCard">
    <div class="image_box">
      <el-image style="
          width: 100%;
          height: 156.5px;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        " fit="cover" :src="cardInfo.image">
      </el-image>
      <i v-if="isShow" class="el-icon-present"></i>
    </div>
    <div class="userDetail">
      <div class="title-date">
        <h4 class="title" :title="cardInfo.title">{{ cardInfo.title }}</h4>
        <p class="date m-0">{{ cardInfo.time }}</p>
        <p v-if="isShow" class="order" :title="cardInfo.order_no">Order Number: {{ cardInfo.order_no }} </p>
      </div>
      <!-- <div v-if="cardInfo.avatar.length > 0" class="d-flex align-items-center mb-2">
        <div class="iq-media-group d-flex align-items-center">
          <a v-for="(v, i) in cardInfo.avatar.slice(0, 3)" :key="i" href="#" class="iq-media">
            <img class="img-fluid rounded-circle" :src="v.avatar" />
          </a>
          <div class="textDesc ml-2">
            <span style="color: #999"> {{ cardInfo.desc }}</span>
          </div>
        </div>
      </div> -->
    </div>
  </iq-card>
</template>
<script>
  export default {
    data() {
      return {};
    },
    props: {
      cardInfo: {
        type: Object,
      },
      isShow: {
        type: Boolean,
        default: false,
      }
    },
    methods: {},
  };
</script>
<style lang="scss" scoped>
  .iqCard {
    /* width: 313px; */
    height: 281px;
    box-shadow: 0px 0px 20px 0px rgba(75, 75, 75, 0.40) !important;

    &:hover {
      box-shadow: 9.19px 9.19px 27.16px 0.39px rgba(75, 75, 75, 0.45) !important;
    }
    .image_box{
      position: relative;
      i{
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 15px;
        color: #fff;
        padding: 10px;
        border-radius: 50%;
        background: #7f7f7f;
      }
    }

    .userDetail {
      height: calc(100% - 155px);
      padding: 10px 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      // 标题和日期
      .title {
        color: #333;
        font-size: 18px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis
      }

      .date {
        font-size: 14px;
        color: #999;
        padding-top: 18px;
        line-height: 1;
      }
      .order {
        color: #666;
        margin: 10px 0;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      // 小头像
      .iq-media {
        width: 24px;
        height: 24px;
        margin-left: -12px !important;

        img {
          width: 100%;
        }
      }

      .iq-media-group .iq-media:first-child {
        margin-left: 0 !important;
      }
    }
  }
</style>